import { useMemo } from 'react'
import { Contract, ethers } from 'ethers'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import useActiveWeb3React from 'hooks/useActiveWeb3React2'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getCakeAddress,
  getVIZSLAHVIAddress,
  getHVIBNBAddress,
  getLotteryV2Address,
  getFarm1Address,
  getFarm2Address,
  getFarm3Address,
  getFarm4Address,
  getFarm5Address,
  getFarm6Address,
  getFarm7Address,
  getFarm8Address,
  getFarm9Address,
  getFarm10Address,
  getFarm11Address,
  getFarm12Address,
  getFarm13Address,
  getFarm14Address,
  getFarm15Address,
  getFarm16Address,
  getFarm17Address,
  getFarm18Address,
  getFarm19Address,
  getFarm20Address,
  getFarm21Address,
  getFarm22Address,
  getFarm23Address,
  getFarm24Address,
  getFarm25Address,
  getFarm26Address,
  getFarm27Address,
  getFarm28Address,
  getVIZSLASWAPLAMEAAddress,
  getMasterChefAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getCakeVaultAddress,
  getCakeVaultAddress2,
  getCakeVaultAddress3,
  getCakeVaultAddress4,
  getCakeVaultAddress5,
  getCakeVaultAddress6,
  getCakeVaultAddress7,
  getCakeVaultAddress8,
  getCakeVaultAddress9,
  getCakeVaultAddress10,
  getHVIAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getBunnySpecialCakeVaultAddress,
  getBunnySpecialPredictionAddress,
  getFarmAuctionAddress,
  getCakeVaultAddressAirSST,
  getFarm29Address,
  getFarmNAddress,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import easterNftAbi from 'config/abi/easterNft.json'
import cakeVaultAbi from 'config/abi/cakeVault.json'
import predictionsAbi from 'config/abi/predictions.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialCakeVaultAbi from 'config/abi/bunnySpecialCakeVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import { ChainLinkOracleContract, FarmAuctionContract, PredictionsContract } from './types'


const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}


// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}
export function getContract2(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function useContract2(abi: any, address: string | undefined, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || address === '0x0000000000000000000000000000000000000000' || !abi || !library) return null
    try {
      return getContract2(address, abi, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, abi, library, withSignerIfPossible, account])
}

export const useMasterchef = () => {
  return useContract2(masterChef, getMasterChefAddress())
}
export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getSouschefContract = (id: number) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress))
} 
export const getSouschefV2Contract = (id: number) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress))
}
export const getPointCenterIfoContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getCakeAddress(), signer)
}
export const getVIZSLAHVIContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getVIZSLAHVIAddress(), signer)
}
export const getFarm1Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm1Address(), signer)
}
export const getFarm2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm2Address(), signer)
}
export const getFarm3Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm3Address(), signer)
}
export const getFarm4Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm4Address(), signer)
}
export const getFarm5Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm5Address(), signer)
}
export const getFarm6Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm6Address(), signer)
}
export const getFarm7Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm7Address(), signer)
}
export const getFarm8Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm8Address(), signer)
}
export const getFarmNContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarmNAddress(), signer)
}
export const getFarm9Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm9Address(), signer)
}
export const getFarm10Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm10Address(), signer)
}
export const getFarm11Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm11Address(), signer)
}
export const getFarm12Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm12Address(), signer)
}
export const getFarm13Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm13Address(), signer)
}
export const getFarm14Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm14Address(), signer)
}
export const getFarm15Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm15Address(), signer)
}
export const getFarm16Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm16Address(), signer)
}
export const getFarm17Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm17Address(), signer)
}
export const getFarm18Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm18Address(), signer)
}
export const getFarm19Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm19Address(), signer)
}
export const getFarm20Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm20Address(), signer)
}
export const getFarm21Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm21Address(), signer)
}
export const getFarm22Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm22Address(), signer)
}
export const getFarm23Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm23Address(), signer)
}
export const getFarm24Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm24Address(), signer)
}
export const getFarm26Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm26Address(), signer)
}
export const getFarm27Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm27Address(), signer)
}
export const getFarm28Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm28Address(), signer)
}
export const getFarm29Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm29Address(), signer)
}
export const getFarm25Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getFarm25Address(), signer)
}
export const getHVIBNBContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getHVIBNBAddress(), signer)
}
export const getVIZSLASWAPLAMEAContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getVIZSLASWAPLAMEAAddress(), signer)
}
export const getHVIContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getHVIAddress(), signer)
}
export const getProfileContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer)
}
export const getPancakeRabbitContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer)
}
export const getBunnyFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer)
}
export const getBunnySpecialContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer)
}
export const getLotteryV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryV2Abi, getLotteryV2Address(), signer)
}
export const getMasterchefContract = () => {
  return getContract(masterChef, getMasterChefAddress())
}  
export const getClaimRefundContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer)
}
export const getTradingCompetitionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer)
}
export const getEasterNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer)
}
export const getCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer)
}
export const getCakeVaultContract2 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress2(), signer)
}
export const getCakeVaultContract3 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress3(), signer)
}
export const getCakeVaultContract4 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress4(), signer)
}
export const getCakeVaultContract5 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress5(), signer)
}
export const getCakeVaultContract6 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress6(), signer)
}
export const getCakeVaultContract7 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress7(), signer)
}
export const getCakeVaultContractAirSST = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddressAirSST(), signer)
}
export const getCakeVaultContract8 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress8(), signer)
}
export const getCakeVaultContract9 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress9(), signer)
}
export const getCakeVaultContract10 = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress10(), signer)
}
export const getPredictionsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer) as PredictionsContract
}

export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainLinkOracleContract
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getBunnySpecialCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialCakeVaultAbi, getBunnySpecialCakeVaultAddress(), signer)
}
export const getBunnySpecialPredictionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialPredictionAbi, getBunnySpecialPredictionAddress(), signer)
}
export const getFarmAuctionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(farmAuctionAbi, getFarmAuctionAddress(), signer) as FarmAuctionContract
}
 