import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { useWeb3React } from "@web3-react/core/core/core";
import { ethers } from "ethers";
import { Button, Text } from "@pancakeswap/uikit";
import contractABI from "./contractABI.json";
import Board from "./components/Board/Board";
import GameInfoBoard from "./components/GameInfoBoard/GameInfoBoard";
import candyData, { CandyData } from "./data/candyColors";
import { createBoard } from "./components/Board/Board.utils";
import GameOver from "./components/GameOver/GameOver";
import {
  generateUnbreakableTiles,
  UNBREAKABLE_TILE_ID,
} from "./components/Board/UnbreakableTile.utils";
import { generateImmovableTiles } from "./components/Board/ImmovableTile.utils";
import { generateHoles } from "./components/Board/hole.utils";
import { levels } from "./data/levels";
import { generateInitialBombs } from "./components/Board/SpecialTiles.utils";

// Supabase kapcsolat inicializálása
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY!;
const supabase = createClient(supabaseUrl, supabaseKey);

// Smart Contract cím
const contractAddress = "0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471";

const App = () => {
  const [level, setLevel] = useState(0);
  const history = useHistory();
  const { account } = useWeb3React();
  const [userName, setUserName] = useState<string | null>(null);
  const [board, setBoard] = useState<number[][]>([]);
  const [isPlayable, setIsPlayable] = useState(true);
  const [moves, setMoves] = useState(0);
  const [tilesToGo, setTilesToGo] = useState(0);
  const [tileToSearch, setTileToSearch] = useState<CandyData | null>(null);
  const [resetTime, setResetTime] = useState(false);
  const [isSavingLevel, setIsSavingLevel] = useState(false); // Új állapot a mentési folyamat nyomon követésére


  const handleRegisterNameClick = () => {
    history.push("/settings");
  };

  // Supabase: Felhasználói szint lekérdezése
  const fetchUserLevel = useCallback(async () => {
    if (!account) return 0;

    const { data, error } = await supabase
      .from("user_levels")
      .select("current_level")
      .eq("user_account", account)
      .single();

    if (error) {
      console.error("Error fetching user level:", error);
      return 0;
    }

    return data?.current_level || 0;
  }, [account]);

  const saveUserLevel = useCallback(
    async (newLevel: number) => {
      if (!account) return;
  
      const { error } = await supabase
        .from("user_levels")
        .upsert(
          {
            user_account: account,
            current_level: newLevel,
            last_played_at: new Date(), // Frissítjük az utolsó játszási időt
            updated_at: new Date(), // Felülírjuk az automatikus frissítést
          },
          { onConflict: "user_account" }
        );
  
      if (error) {
        console.error("Error saving user level:", error);
      }
    },
    [account]
  );
  

  // Smart Contract kapcsolat
  const getContract = useCallback(() => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org"
    );
    return new ethers.Contract(contractAddress, contractABI, provider);
  }, []);

  // Felhasználónév lekérdezése
  const fetchUserName = useCallback(async () => {
    if (!account) return;

    try {
      const contract = getContract();
      const name = await contract.getName(account);
      setUserName(name || null);
    } catch (error) {
      console.error("Error fetching user name:", error);
      setUserName(null);
    }
  }, [account, getContract]);

  // Tábla érvényesítése
  const validateBoard = (board: number[][]) => {
    const positions = new Set();
    for (let row = 0; row < board.length; row++) {
      for (let col = 0; col < board[row].length; col++) {
        const tile = board[row][col];
        if (tile !== 0) {
          const positionKey = `${row}-${col}`;
          if (positions.has(positionKey)) {
            console.error(
              `Overlapping tiles detected at position (${row}, ${col})`
            );
          } else {
            positions.add(positionKey);
          }
        }
      }
    }
    console.log("Board validation complete. No overlaps found.");
  };

  // Szint inicializálása
  const initializeLevel = useCallback(
    (levelIndex: number) => {
      console.log(`Initializing level ${levelIndex}...`);
      setMoves(levels[levelIndex].MAX_MOVES);
      setTilesToGo(levels[levelIndex].NUM_TILES_TO_GO);
      setIsPlayable(true);

      let initialBoard = createBoard(
        levels[levelIndex].BOARD_SIZE,
        levels[levelIndex].NUMBERS
      );

      initialBoard = generateUnbreakableTiles(
        initialBoard,
        levels[levelIndex].UNBREAKABLE_TILE_COUNT
      );
      initialBoard = generateImmovableTiles(
        initialBoard,
        levels[levelIndex].IMMOVABLE_TILE_COUNT
      );
      if (levels[levelIndex].HOLES_COUNT) {
        initialBoard = generateHoles(
          initialBoard,
          levels[levelIndex].HOLES_COUNT
        );
      }
      initialBoard = generateInitialBombs(
        initialBoard,
        levels[levelIndex].BOMB_COUNT
      );

      validateBoard(initialBoard);

      const targetTileIds = levels[levelIndex].targetTileIds;
      const randomTargetId = _.sample(targetTileIds);
      const tileData = candyData.find((candy) => candy.tileId === randomTargetId);

      setTileToSearch(tileData || null);
      setBoard(initialBoard);
    },
    []
  );

  useEffect(() => {
    fetchUserName();
  }, [fetchUserName]);

  useEffect(() => {
    const startGame = async () => {
      const userLevel = await fetchUserLevel(); // Csak a mentett szintet kérjük le
      setLevel(userLevel); // Beállítjuk a szintet, de nem mentjük el
      initializeLevel(userLevel);
    };
  
    if (account) {
      startGame();
    }
  }, [account, fetchUserLevel, initializeLevel]);
  

  const move = useCallback(() => setMoves((prev) => prev - 1), []);

  const subsTilesToGo = (id: number, quan: number) => {
    if (id === UNBREAKABLE_TILE_ID || id !== tileToSearch?.tileId || id === 0) {
      console.log(
        `Skipping tile with ID ${id}. Reason: not target, unbreakable, or empty.`
      );
      return;
    }
    setTilesToGo((prev) => {
      const newTilesToGo = Math.max(prev - quan, 0);
      if (newTilesToGo === 0) {
        console.log("You've won! No tiles left to collect.");
        setIsPlayable(false); // Játék leállítása
        setTimeout(() => nextLevel(), 4000); // Automatikus szintlépés kis késleltetéssel
      }
      return newTilesToGo;
    });
  };
  
  const newGame = () => {
  initializeLevel(level); // Újrainicializálja az aktuális szintet
  setResetTime(true); // Timer resetelése (ha van ilyen logikád)
  setTimeout(() => setResetTime(false), 0);
};
  const nextLevel = async () => {
    const newLevel = level + 1;
  
    // Azonnal frissítjük az állapotot
    setLevel(newLevel);
    setIsSavingLevel(true);
  
    try {
      await saveUserLevel(newLevel);
      console.log("User level successfully saved to Supabase.");
  
      // Csak akkor inicializáljuk a szintet, ha a mentés sikeres volt
      initializeLevel(newLevel);
    } catch (error) {
      console.error("Error saving user level:", error);
    } finally {
      setIsSavingLevel(false);
    }
  };
  
  const gameOver = useMemo(() => moves === 0 || tilesToGo === 0, [moves, tilesToGo]);

  return (
    <div>
    <h1
  style={{
    textAlign: "center",
    fontSize: "3rem",
    marginTop: "10px",
    marginBottom: "10px",
    color: "#FFD700",
  }}
>
  Sugar Vizsla
</h1>

      {!account ? (
        <div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
     textAlign: "center", // Középre igazított szöveg
  }}
>
  <Text>Please connect your wallet to play the game.</Text>
</div>      ) : !userName ? (
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text mb="10px">Please register a name to start the game.</Text>
        <Button onClick={handleRegisterNameClick} variant="gold">
          Register Your Name
        </Button>
      </div>
      
      ) : (
        <>
          <GameInfoBoard
            moves={moves}
            tilesToGo={tilesToGo}
            tileToSearch={tileToSearch}
            level={level + 1}
          />
          {tileToSearch?.tileId ? (
            <Board
              move={move}
              subsTilesToGo={subsTilesToGo}
              isPlayable={isPlayable}
              setIsPlayable={setIsPlayable}
              board={board}
              setBoard={setBoard}
              numbers={levels[level].NUMBERS}
            />
          ) : (
            "Loading..."
          )}
         {gameOver && (
  <GameOver
    isWin={tilesToGo === 0}
    onRetry={newGame} // Újraindítás logikájának átadása
  />
)}
        </>
      )}
    </div>
  );
};

export default App;
