import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import { Button } from '@pancakeswap/uikit';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

// Styled-components
const ToplistContainer = styled.div`
  padding: 30px;
  max-width: 700px;
  margin: 0 auto;
  background-color: #2b3a42;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #eaeaea;
`;

const TopListTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
`;

const CountdownContainer = styled.div`
  font-size: 18px;
  color: gold;
  margin: 20px 0;
  padding: 10px;
  background-color: #24343a;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;

const Description = styled.p`
  font-size: 16px;
  color: #ccc;
  margin-bottom: 20px;
`;

const UserList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const UserItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background-color: ${({ index }) => (index % 2 === 0 ? '#24343a' : '#2b3a42')};
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;

const UserName = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const UserLevel = styled.span`
  font-size: 16px;
  color: #ffd700;
`;

const UserTokens = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const BackButton = styled(Button)`
  margin-bottom: 20px;
  background-color: #e6ac00;
  color: #fff;
  &:hover {
    background-color: #d4a017;
  }
`;

// Toplist Component
const Toplist = () => {
  const [topList, setTopList] = useState([]);
  const [names, setNames] = useState({});
  const [timeLeft, setTimeLeft] = useState('');

  // Fetch the top list from Supabase
  useEffect(() => {
    const fetchTopList = async () => {
      const { data: userLevels, error } = await supabase
        .from('user_levels')
        .select('user_account, current_level')
        .order('current_level', { ascending: false });

      if (!error) {
        setTopList(userLevels);

        // Fetch user names from the second table
        const accounts = userLevels.map((user) => user.user_account);
        const { data: userData, error: nameError } = await supabase
          .from('user_data')
          .select('account, name')
          .in('account', accounts);

        if (!nameError) {
          const nameMapping = userData.reduce((acc, user) => {
            return {
              ...acc,
              [user.account]: user.name,
            };
          }, {});
          setNames(nameMapping);
        }
      }
    };

    fetchTopList();
  }, []);

  // Countdown Timer
  useEffect(() => { 
    const targetDate = new Date('2025-03-31T00:00:00');
    const countdown = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        clearInterval(countdown);
        setTimeLeft('The competition has ended.');
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const calculateTokens = (level) => {
    if (level < 300) {
      return '0 VizslaSwap';
    }
    return `${level} VizslaSwap`;
  };

  return (
    <ToplistContainer>
      <Link to="/sugarvizsla">
        <BackButton>&lt; Back to SugarVizsla</BackButton>
      </Link>

      <CountdownContainer>
        <div>Time left until the competition ends:</div>
        <div>{timeLeft}</div>
      </CountdownContainer>
      <TopListTitle>Top List</TopListTitle>
      <Description>
        You earn 1 VizslaSwap token for every level completed above 300. Below
        300 levels, you earn 0 tokens.
      </Description>

      <UserList>
        {topList.map((user, index) => (
          <UserItem key={user.user_account} index={index}>
            <UserName>
              {names[user.user_account] ||
                `${user.user_account.substring(0, 5)}...${user.user_account.substring(
                  user.user_account.length - 5
                )}`}
            </UserName>
            <UserLevel>Level {user.current_level}</UserLevel>
            <UserTokens>{calculateTokens(user.current_level)}</UserTokens>
          </UserItem>
        ))}
      </UserList>
    </ToplistContainer>
  );
};

export default Toplist;
