import React, { FC } from "react";
import { useHistory } from 'react-router-dom'; // useHistory a react-router-dom v5-höz
import { Button} from "@pancakeswap/uikit";
import * as S from "./GameInfoBoard.styles";
import { CandyData } from "../../data/candyColors";
import { candies } from "../../assets";

interface GameInfoBoardProps {
  moves: number;
  tilesToGo: number;
  tileToSearch: CandyData | null;
  level: number;
}

const GameInfoBoard: FC<GameInfoBoardProps> = ({
  moves,
  tilesToGo,
  tileToSearch,
  level,
}) => {
  const history = useHistory(); // useHistory használata a v5-ben
  const goToToplist = () => {
    history.push('/sugartoplist'); // Átirányítás a toplistára
  };
  return (
    <S.Wrapper>
          
      <p>Level: {level}</p>
      <p>Moves: {moves}</p>
      {tileToSearch?.tileId && (
        <p>
           <img
            src={candies[tileToSearch.tileId]}
            width="30"
            alt="Tile to search"
          />
          <span className="lover"> x </span> {tilesToGo}
        </p>
      )}
        <Button variant="gold" onClick={goToToplist} width="50px">
      🏆
    </Button>
    </S.Wrapper>
  );
};

export default GameInfoBoard;
