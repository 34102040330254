import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom'; // useHistory a react-router-dom v5-höz
import styled, { css,keyframes } from 'styled-components'
import { createClient } from '@supabase/supabase-js';
import { useWeb3React } from '@web3-react/core/core/core';
import { ethers } from 'ethers'; 
import { Button, Input, Heading , Text} from '@pancakeswap/uikit';
import contractABI from './contractABI.json'; // Add contract ABI import for `getName` 

const dropCard = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100px) rotate(-15deg) scale(1.2); /* Kezdeti pozíció fentebb és kissé nagyobb */
  }
  70% {
    opacity: 1;
    transform: translateY(10px) rotate(2deg) scale(1); /* Pozíció középen, enyhe elforgatás */
  }
  100% {
    transform: translateY(0) rotate(0deg); /* Végső pozíció */
  }
`;

// Animációval rendelkező kártyaelem komponens
const StyledCard = styled.div`
  width: 50px;
  height: 75px;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px; 
  font-weight: bold;
  animation: ${css`${dropCard} 0.6s ease-out`};
  animation-fill-mode: forwards;
`;
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);
const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 2.5px #FFF, 0 0 5px #FFF, 0 0 7.5px #FFF, 0 0 10px #FFF;
  }
  50% {
    text-shadow: 0 0 2.5px #FFF, 0 0 5px #FFF, 0 0 7.5px #FFF, 0 0 10px #FFF;
  }
  100% {
    text-shadow: 0 0 2.5px #FFF, 0 0 5px #FFF, 0 0 7.5px #FFF, 0 0 10px #FFF;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Távolság a cím és a kép között */
  margin-bottom: 20px;
`;
const RulesBox = styled.div`
background-color: #000;
border: 2px solid gold;
border-radius: 10px;
padding: 20px;
margin-top: 20px;
max-width: 600px;
text-align: left;
color: #fff;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
font-size: 16px;
line-height: 1.5;

ul {
  padding-left: 20px;
}

p {
  margin-bottom: 10px;
}
`;
const RulesList = styled.ul`
list-style-type: decimal;
padding-left: 20px;

li {
  margin-bottom: 10px;
}
`;
const LanguageButton = styled(Button)`
margin-top: 10px;
width: 200px;
background-color: #ffcc00;
border-color: #ffcc00;
color: #000;
&:hover {
  background-color: #ffaa00;
  border-color: #ffaa00;
}
`;
const rules = {
  EN: [
    { id: 'rule1', text: "Every player starts with 10,000 points. If your points reach zero, you will automatically receive 10,000 points again to continue playing." },
    { id: 'rule2', text: "Every 2 weeks, players have a chance to win VizslaSwap tokens." },
    { id: 'rule3', text: "Only users with a registered username can participate in the game." },
    { id: 'rule4', text: "The goal of the game is to get as close to 21 as possible without going over." },
    { id: 'rule5', text: "Face cards (Jack, Queen, King) are worth 10 points, and Aces are worth 1 or 11." },
    { id: 'rule6', text: "You start with two cards, and can choose to draw more (Draw Card) or keep your hand (Stand)." },
    { id: 'rule7', text: "The dealer must hit until they have at least 17 points." },
    { id: 'rule8', text: "If your score exceeds 21, you lose the game." },
    { id: 'rule9', text: "If you tie with the dealer, it's a draw, and you get your bet back." }
  ],
  HU: [
    { id: 'rule1', text: "Minden játékos 10 000 ponttal kezd. Ha elveszíted az összes pontodat, folytathatod 10 000 pontal a játékot." },
    { id: 'rule2', text: "Minden 2 hétben a játékosoknak lehetőségük van VizslaSwap tokeneket nyerni." },
    { id: 'rule3', text: "Csak felhasználónévvel rendelkező játékosok vehetnek részt a játékban." },
    { id: 'rule4', text: "A játék célja, hogy minél közelebb kerülj a 21 ponthoz anélkül, hogy túllépnéd azt." },
    { id: 'rule5', text: "Az arckártyák (Bubi, Dáma, Király) értéke 10 pont, az ászok értéke 1 vagy 11 pont." },
    { id: 'rule6', text: "Két kártyával kezdesz, és dönthetsz, hogy kérsz-e még (Draw Card) vagy megtartod a kezed (Stand)." },
    { id: 'rule7', text: "Az osztónak ütni kell, amíg el nem éri legalább a 17 pontot." },
    { id: 'rule8', text: "Ha a pontszámod meghaladja a 21-et, veszítesz." },
    { id: 'rule9', text: "Ha az osztóval döntetlent érsz el, visszakapod a tétedet." }
  ],
};

const Title = styled(Heading)`
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
    color: #000;
    margin-top: 32px;
`;
const cardValues = {
  '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9, '10': 10,
  'J': 10, 'Q': 10, 'K': 10, 'A': 11,
};
 
const cardSuits = ['heart', 'spade', 'diamond', 'club'];
const cards = Object.keys(cardValues);

const BlackJack = () => {
  const { account } = useWeb3React();
  const [playerScore, setPlayerScore] = useState<number>(0);
  const [dealerScore, setDealerScore] = useState<number>(0);
  const [playerHand, setPlayerHand] = useState<string[]>([]);
  const [dealerHand, setDealerHand] = useState<string[]>([]);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [deck, setDeck] = useState<string[]>([]);
  const [winner, setWinner] = useState<string | null>(null);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [canPlay, setCanPlay] = useState<boolean>(true);
  const [userTotalPoints, setUserTotalPoints] = useState<number>(0);
  const [isGameActive, setIsGameActive] = useState(false);
  const [bet, setBet] = useState<number>(0);
  const [isClaimable, setIsClaimable] = useState(false);
  const [isShuffling, setIsShuffling] = useState<boolean>(false);
  const [hasShuffled, setHasShuffled] = useState<boolean>(false);
  const [lastReset, setLastReset] = useState<Date | null>(null);
const [resetCountdown, setResetCountdown] = useState<string>('');
  const [timer, setTimer] = useState<number>(12);  // Hozzáadott időzítő állapot
  const history = useHistory(); // useHistory használata a v5-ben
  const goToToplist = () => {
    history.push('/blackjacktoplist'); // Átirányítás a toplistára
  };
  const [userName, setUserName] = useState<string | null>(null); // Added state for user name
  const handleRegisterNameClick = () => {
    history.push('/settings'); // Navigate to the settings page to register name
  };

  const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471';  // Add your contract address

  // Get contract
  const getContract = useCallback(() => {
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
    return new ethers.Contract(contractAddress, contractABI, provider);
  }, []);

  // Function to fetch the user's name
  const fetchUserName = useCallback(async () => {
    if (!account) return;

    try {
      const contract = getContract();
      const name = await contract.getName(account); // Call contract to get the user's name
      if (name) {
        setUserName(name);
      } else {
        setUserName(null); // No name found
      }
    } catch (error) {
      console.error('Error fetching user name:', error);
      setUserName(null);
    }
  }, [account, getContract]);

  useEffect(() => {
    fetchUserName();
  }, [fetchUserName]);
  const [language, setLanguage] = useState<'EN' | 'HU'>('EN'); // Nyelv váltása
  const toggleLanguage = () => {
    setLanguage(prevLang => (prevLang === 'EN' ? 'HU' : 'EN'));
  };
  
  const shuffleDeck = useCallback(() => {
    const shuffled = [...cards, ...cards, ...cards, ...cards].sort(() => Math.random() - 0.5);
    setDeck(shuffled);
  }, []);

  const calculateScore = useCallback((hand: string[]) => {
    let score = hand.reduce((acc, card) => acc + cardValues[card], 0);
    let aceCount = hand.filter(card => card === 'A').length;

    while (score > 21 && aceCount > 0) {
      score -= 10;
      aceCount--;
    }
    return score;
  }, []);

  const saveScore = useCallback(async (newScore: number) => {
    if (!account) return;
  
    const { error } = await supabase
      .from('blackjack_scores')
      .update({ score: newScore })
      .eq('account', account);
  
    if (error) {
      console.error('Error updating score:', error.message);
    }
  }, [account]);

  const checkResetTimer = useCallback((lastResetTime) => {
    const now = new Date();
    if (!lastResetTime) return;
  
    const hoursPassed = (now.getTime() - lastResetTime.getTime()) / (1000 * 60 * 60);
  
    if (hoursPassed >= 12) {
      setIsClaimable(true); // Gomb aktív lesz
      setResetCountdown('Ready to claim');
    } else {
      const remainingTime = 12 - hoursPassed;
      const hours = Math.floor(remainingTime);
      const minutes = Math.floor((remainingTime - hours) * 60);
      setResetCountdown(`${hours}h ${minutes}m remaining to claim free points`);
      setIsClaimable(false); // Gomb letiltott állapotban marad
    }
  }, []);
  

  const handleClaimPoints = useCallback(async () => {
    if (!account || !isClaimable) return; // Csak akkor gyűjthető be, ha elérhető
  
    setUserTotalPoints(10000);
    await saveScore(10000); // Pontok frissítése az adatbázisban
    setIsClaimable(false); // Gomb újra letiltott állapotba kerül
    const now = new Date();
    setLastReset(now); // Visszaállítjuk a `last_reset` időpontot
    setResetCountdown("12h 0m remaining"); // Visszaállítjuk az időzítőt
  }, [account, isClaimable, saveScore]);

  const fetchOrInitializeUserPoints = useCallback(async () => {
    if (!account) return;
  
    try {
      // Fetch the user's score and last reset time
      const { data, error } = await supabase
        .from('blackjack_scores')
        .select('score, last_reset')
        .eq('account', account)
        .single();
  
      if (error) {
        // If the user is not found, initialize them with 10,000 points and set the reset time
        if (error.code === 'PGRST116') {
          const now = new Date();
          const { error: insertError } = await supabase
            .from('blackjack_scores')
            .insert([{ account, score: 10000, last_reset: now.toISOString() }]);
  
          if (insertError) {
            console.error('Error initializing user score:', insertError.message);
          } else {
            setUserTotalPoints(10000);
            setLastReset(now);
          }
        } else {
          console.error('Error fetching user score:', error.message);
        }
      } else {
        // If user exists, check if they have 0 points and apply the 12-hour reset logic
        setUserTotalPoints(data.score);
        const lastResetTime = data.last_reset ? new Date(data.last_reset) : null;
        setLastReset(lastResetTime);
  
        if (data.score === 0) {
          checkResetTimer(lastResetTime);
        }
      }
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  }, [account,checkResetTimer]);

  const startNewGame = useCallback(async () => {
    if (!userName) {
      alert('Please register a name before playing.');
      return;
    }
    if (!canPlay || bet <= 0 || !bet || bet > userTotalPoints) {
      alert('Invalid bet! Please enter a valid amount greater than 0.');
      return;
    }
  
    setIsGameActive(true); // Játék elindulása
  
    if (!gameStarted && !hasShuffled) {
      setIsShuffling(true);
      shuffleDeck();
      setTimeout(() => {
        setIsShuffling(false);
        setHasShuffled(true); // Csak a kártyák keverése történik itt
      }, 500);
      return;
    }
  
    // Pontlevonás csak akkor, ha még nem történt meg
    if (!gameStarted) {
      const updatedPoints = userTotalPoints - bet;
      setUserTotalPoints(updatedPoints);
      await saveScore(updatedPoints); // Csak itt von le pontot
    }
  
    // A játék elindítása a megkevert kártyákkal
    const playerInitialHand = [deck[0], deck[2]];
    const dealerInitialHand = [deck[1], deck[3]];
  
    setPlayerHand(playerInitialHand);
    setDealerHand(dealerInitialHand);
    setPlayerScore(calculateScore(playerInitialHand));
    setDealerScore(calculateScore(dealerInitialHand.slice(0, 1)));
    setGameOver(false);
    setWinner(null);
    setDeck(deck.slice(4));
    setGameStarted(true); // Itt kezdődik el ténylegesen a játék
  
    setCanPlay(false);
  
    let count = 12;
    const countdown = setInterval(() => {
      count--;
      setTimer(count);
      if (count === 0) {
        setCanPlay(true);
        clearInterval(countdown);
      }
    }, 1000);
  }, [deck, shuffleDeck, calculateScore, canPlay, userName, bet, userTotalPoints, hasShuffled, gameStarted, saveScore]);
  
  
  const playerDrawCard = () => {
    if (deck.length === 0) return;

    const card = deck[0];
    const newHand = [...playerHand, card];
    const newScore = calculateScore(newHand);

    setPlayerHand(newHand);
    setPlayerScore(newScore);
    setDeck(deck.slice(1));

    if (newScore > 21) {
      setGameOver(true);
      setWinner('Dealer');
      applyPenalty();
      deleteGameState();  // Törlés azonnal, ha a játékos veszít
    }
  };
  const updateGameState = useCallback(async (updates) => {
    if (!account) return;
  
    const { error } = await supabase
      .from('blackjack_game_state')
      .update(updates)
      .eq('account', account);
  
    if (error) {
      console.error('Error updating game state:', error.message);
    }
  }, [account]);
  
  const dealerTurn = async () => {
    let dealerNewScore = calculateScore(dealerHand);
    let dealerNewHand = [...dealerHand];
  
    while (dealerNewScore < 17 && deck.length > 0) {
      const card = deck[0];
      dealerNewHand = [...dealerNewHand, card];
      dealerNewScore = calculateScore(dealerNewHand);
      setDeck(deck.slice(1));
    }
  
    setDealerHand(dealerNewHand);
    setDealerScore(dealerNewScore);
  
    if (dealerNewScore > 21 || playerScore > dealerNewScore) {
      setWinner('Player');
      const pointsWon = bet * 2;
      const newTotalPoints = userTotalPoints + pointsWon;
  
      await saveScore(newTotalPoints); // Csak a nyereményt adjuk hozzá
      setUserTotalPoints(newTotalPoints);
    } else if (dealerNewScore === playerScore) {
      setWinner('Draw');
      const newTotalPoints = userTotalPoints + bet; // A levont tét visszaadása döntetlen esetén
      await saveScore(newTotalPoints);
      setUserTotalPoints(newTotalPoints);
    } else {
      setWinner('Dealer');
      await applyPenalty(); // Veszteség esetén nincs pont visszaállítás
    }
  
    setGameOver(true);
    deleteGameState(); // Azonnal töröljük a játékállást
  };
  
  const saveGameState = useCallback(async () => {
    if (!account) return;
  
    const gameState = [{
      account,
      player_hand: JSON.stringify(playerHand),
      dealer_hand: JSON.stringify(dealerHand),
      deck: JSON.stringify(deck),
      game_started: gameStarted,
      bet,  // Mentjük a tétet is
    }];
  
    const { error } = await supabase
      .from('blackjack_game_state')
      .upsert(gameState, { onConflict: 'account' });
  
    if (error) {
      console.error('Error saving game state:', error.message);
    }
  }, [account, playerHand, dealerHand, deck, gameStarted, bet]);
  
  const applyPenalty = useCallback(async () => {
    if (!account) return;
  
    const updatedPoints = Math.max(0, userTotalPoints); // A pontszám nem csökkenhet 0 alá
    setUserTotalPoints(updatedPoints);
  
    if (updatedPoints === 0) {
      const now = new Date();
      setLastReset(now);
      setResetCountdown('12h 0m remaining');
  
      await supabase
        .from('blackjack_scores')
        .update({ score: 0, last_reset: now.toISOString() })
        .eq('account', account);
    } else {
      await saveScore(updatedPoints);
    }
  }, [account, userTotalPoints, saveScore]);
  
  

  const fetchSavedGameState = useCallback(async () => {
    if (!account) return;
  
    const { data, error } = await supabase
      .from('blackjack_game_state')
      .select('*')
      .eq('account', account)
      .single();
  
    if (data) {
      // Ellenőrizzük, hogy a játék már véget ért-e
      if (data.game_ended) {
        alert("This game has already ended. You cannot modify the result.");
        setGameOver(true); // A játék véget ért
        return;  // Ne töltsük be újra az állapotot
      }
  
      // Beállítjuk a mentett kártyákat, decket és játékállapotot
      const savedPlayerHand = JSON.parse(data.player_hand);
      const savedDealerHand = JSON.parse(data.dealer_hand);
      const savedDeck = JSON.parse(data.deck);
  
      setPlayerHand(savedPlayerHand);
      setDealerHand(savedDealerHand);
      setDeck(savedDeck);
      setGameStarted(data.game_started);
      setBet(data.bet);  // Beállítjuk a mentett tétet
  
      // Pontszám frissítése a mentett kártyák alapján
      const playerNewScore = calculateScore(savedPlayerHand);
      setPlayerScore(playerNewScore);
  
      const dealerNewScore = calculateScore(savedDealerHand);
      setDealerScore(dealerNewScore);
      
      setGameOver(true);  // Az állapot frissítés után lezárjuk a játékot
    } else if (error && error.code !== 'PGRST116') {
      console.error('Error fetching saved game state:', error.message);
    }
  }, [account, calculateScore]);
  
  const deleteGameState = useCallback(async () => {
    if (!account) return;
  
    const { error } = await supabase
      .from('blackjack_game_state')
      .delete()
      .eq('account', account);
  
    if (error) {
      console.error('Error deleting game state:', error.message);
    }
  }, [account]);
   // Töröljük a játékállást, ha vége a játéknak
   useEffect(() => {
    if (gameOver) {
      deleteGameState();  // Töröljük a játékállást a játék végeztével
    }
  }, [gameOver, deleteGameState]);

  const resetGame = useCallback(() => {
    if (!account) return;
  
    // Ellenőrizzük, hogy letelt-e a 12 óra
    if (userTotalPoints === 0 && lastReset) {
      const hoursSinceReset = (new Date().getTime() - lastReset.getTime()) / (1000 * 60 * 60);
      if (hoursSinceReset >= 12) {
        alert('Your points have been reset to 10,000 to continue playing!');
        setUserTotalPoints(10000);
        saveScore(10000); // Pontok frissítése 10,000-re, ha eltelt 12 óra
      } else {
        // Ha még nem telt le a 12 óra, megjelenítjük a hátralévő időt
        setResetCountdown(`${Math.floor(12 - hoursSinceReset)}h ${Math.floor((12 - hoursSinceReset) * 60 % 60)}m remaining`);
      }
    }
  
    shuffleDeck();
    setGameStarted(false);
    setHasShuffled(true);
    setTimer(10); // Újraindítjuk az időzítőt
  }, [account, shuffleDeck, userTotalPoints, lastReset, saveScore]);
  

  const renderCard = (card: string, index: number) => {
    const suitSymbols = {
      heart: '♥️',
      spade: '♠️',
      diamond: '♦️',
      club: '♣️',
    };

    // Véletlenszerűen választunk ki egy jelet minden kártyához
    const suit = cardSuits[index % cardSuits.length]; // Index alapján váltogatjuk a jeleket
    const color = suit === 'heart' || suit === 'diamond' ? 'red' : 'black'; // Színes kártyák

    return (
      <StyledCard key={`card-${index}`} style={{ ...styles.card, color }}>
      {card} {suitSymbols[suit]}
    </StyledCard>
    ); 
  };

  useEffect(() => {
    fetchOrInitializeUserPoints(); // Betöltjük a felhasználói pontokat
    fetchSavedGameState(); // Betöltjük a mentett játékállapotot, ha van
  }, [fetchOrInitializeUserPoints, fetchSavedGameState]);
useEffect(() => {
  if (gameStarted) {
    saveGameState();  // Mentjük a játék állapotát, ha a játék elindult
  }
}, [playerHand, dealerHand, deck, gameStarted, saveGameState]);

    return (
      <>
    
        <div style={styles.blackjackGame}>
          <div style={styles.mainContainer}>
          {!isGameActive && (
    <>
      <TitleContainer>
        <Title as="h1" scale="xxl">BlackJack</Title>
        <img src="images/pixel/7077.png" alt="BlackJack Icon" style={{ width: '70px', height: '70px' }} />
      </TitleContainer>
    </>
  )}
          <p style={styles.totalPoints}>Your Total Points: {userTotalPoints}</p>
          </div>
    
          {account ? (
        <>
          {!userName ? ( // Ha nincs név, jelenjen meg a Register gomb
            <Button onClick={handleRegisterNameClick} variant="gold">
              Register Your Name
            </Button>
          ) : (
            <>
              {isShuffling ? (
                <Button disabled>Shuffling cards...</Button>
              ) : gameStarted ? (
                <>
                  <div style={styles.dealerContainer}>
                    <h2>Dealer&apos;s Hand</h2>
                    <div style={styles.cardContainer}>
                      <div style={styles.card}>{dealerHand[0]}</div>
                      {gameOver ? dealerHand.slice(1).map((card, index) => (
                        <div key={`dealer-card-${card}`}>
                          {renderCard(card, index)}
                        </div>
                      )) : <div style={styles.hiddenCard}>?</div>}
                    </div>
                    <p style={{ fontWeight: 'bold' }}>Dealer Score: {gameOver ? dealerScore : '?'}</p>
                    </div>
    
                  <div style={styles.playerContainer}>
                    <h2>Your Hand</h2>
                    <div style={styles.cardContainer}>
                      {playerHand.map((card, index) => (
                        <div key={`player-card-${card}`}>
                          {renderCard(card, index)}
                        </div>
                      ))}
                    </div>
                    <p style={{ fontWeight: 'bold' }}>Your Score: {playerScore}</p>
                    </div>
    
                  {winner ? (
                    <p style={winner === 'Player' ? styles.winMessage : styles.loseMessage}>
                      {winner === 'Draw' ? 'It\'s a Draw!' : `${winner} wins!`}
                    </p>
                  ) : ( 
                    <div style={styles.buttonContainer}>
                      <Button variant="gold" onClick={playerDrawCard} disabled={gameOver}>Draw Card</Button>
                      <Button variant="gold" onClick={dealerTurn} disabled={gameOver || playerScore > 21}>Stand</Button>
                    </div>
                  )}
    
                  {gameOver && <Button variant="primary" className="reset" onClick={resetGame}>Reset</Button>}
                </>
              ) : (
                <>
                  <div>
                    <Text  bold>Bet amount:</Text>
                    <Input
                      id="betInput"
                      name="betAmount"
                      type="number"
                      value={bet}
                      min={1}
                      max={userTotalPoints}
                      onChange={(e) => setBet(parseInt(e.target.value, 10))}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '10px' }}>
  <Button variant="gold" onClick={startNewGame} disabled={!canPlay} width="200px">
    {hasShuffled || gameOver ? 'New Game' : 'Shuffling cards...'}
    {!canPlay && <div style={{ marginTop: '0px' }}>{timer}</div>}
  </Button>

  <Button variant="gold" onClick={goToToplist} width="50px">
    🏆
  </Button>
</div>


{/* Claim pont gomb külön sorban a New Game alatt */}
{userTotalPoints === 0 && (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
    {isClaimable ? (
      <>
        <Button variant="primary" onClick={handleClaimPoints} width="200px">
          Claim 10,000 Points
        </Button>
      </>
    ) : (
      resetCountdown && (
        <Text>{resetCountdown}</Text>
      )
    )}
  </div>
)}


<RulesBox>
            <Text fontSize="20px" bold>{language === 'EN' ? 'Rules:' : 'Szabályzat:'}</Text>
            <RulesList>
  {rules[language].map((rule) => (
    <li key={rule.id}>{rule.text}</li> 
  ))}
</RulesList>
            <LanguageButton onClick={toggleLanguage}>
              {language === 'EN' ? 'Switch to Hungarian' : 'Váltás angolra'}
            </LanguageButton>
            </RulesBox>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <p>Please connect your wallet to start the game.</p>
      )}
    </div>
      </>
    );    
};

const styles: { [key: string]: React.CSSProperties } = {
  blackjackGame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#192f30',
    color: '#fff',
    padding: '30px',
    borderRadius: '30px',
    boxShadow: '0 5px 25px rgba(0, 0, 0, 0.7)',
    maxWidth: '900px',
    margin: '20px auto',
    border: '4px solid #000',
        marginBottom: '30px', // Hozzáadva a margin-bottom

  },
  mainContainer: {
    marginBottom: '30px',
  },
  totalPoints: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: 'white',
    marginBottom:'-10px',  
  },
  winMessage: {
    color: 'gold',
    fontSize: '30px',
    fontWeight: 'bold',
    textShadow: '0 0 10px black, 0 0 20px black, 0 0 30px black',
    marginTop: '20px',
    marginBottom: '20px',
  },
  loseMessage: {
    color: 'white',
    fontSize: '30px',
    fontWeight: 'bold',
    textShadow: '0 0 10px red, 0 0 20px red, 0 0 30px red',
    marginTop: '20px',
    marginBottom: '20px',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '2px solid gold',
    padding: '20px',
    borderRadius: '10px',
    width: '100%',
    maxWidth: '320px',
    height: 'auto',
    position: 'relative',
    gap: '5px',
    marginTop: '3px', 
    marginBottom: '3px',  
  },
  card: {
    width: '50px',
    height: '75px',
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: '8px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  hiddenCard: {
    width: '50px',
    height: '75px',
    backgroundColor: '#000',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#fff',
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '10px',
    gap: '10px',
  },
  dealerContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  playerContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  betInput: {
    marginTop: '10px',
    width: '100px',
  },
};

export default BlackJack;
 