import { ChainId as UniswapChainId } from '@uniswap/sdk'
import { ChainId as PancakeswapChainId } from '@pancakeswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId: number]: string } = {
  // Ethereum alapú hálózatok
  [UniswapChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [UniswapChainId.ROPSTEN]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
  [UniswapChainId.RINKEBY]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [UniswapChainId.GÖRLI]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [UniswapChainId.KOVAN]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  
  // BSC alapú hálózatok
  [PancakeswapChainId.MAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [PancakeswapChainId.TESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }

// Funkció a megfelelő multicall cím visszaadására
export const getMulticallAddress = (chainId: number) => {
  return MULTICALL_NETWORKS[chainId]
}
