import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon', 
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/pool',
      },
      {
        label: t('Limit-Orders'),
        href: '/limit-orders',
      },
      {
        label: t('Charts'),
        href: '/charts',
      },
    ],
  },
  {
    label: t('Staking'),
    icon: 'PredictionsIcon',
    items: [
      {
        label: t('Liquidity Mining'),
        href: '/mining',
      },
      {
        label: t('Staking Pools'),
        href: '/pools/all',
      },
      {
        label: t('B2Mining'),
        href: '/be2mining/all',
      },
    ],
  },
  {
    label: t('Play'),
    icon: 'PartnerIcon',
    items: [
      {
        label: t('SugarVizsla'),
        href: '/sugarvizsla',
      },
      {
        label: t('Quiz'),
        href: '/quiz',
      },
      {
        label: t('BlackJack'),
        href: '/blackjack',
      },
    ],
    
  },
  {
    label: t('Community'),
    icon: 'CharityIcon',
    items: [
      {
        label: t('Chat'),
        href: '/chat',
      },
      {
        label: t('Referral'),
        href: '/referral',
      },
      {
        label: t('Donation'),
        href: '/donation',
      },
    ],
  },
      {
        label: t('Learn'),
        icon: 'MoreIcon',
        items: [
          {
            label: t('Docs'),
            href: '/partnership',
          },
        ],
        
      },
      
    ]
  

export default config
