import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core/core/core';
 import { ButtonMenu, ButtonMenuItem, Flex, Button, Toggle } from '@pancakeswap/uikit';
import ConnectWalletButton from 'components/ConnectWalletButton';
import { createClient } from '@supabase/supabase-js';
import { useTranslation } from 'contexts/Localization';
import { Avatar, UserButton, UserList, UserListItem,Container2, Sidebar,EnterText,InputWrapper,MessageContainer, Container, Timestamp, ChatBox, SendButton, Input, Message, Title } from './Styled';
 
import EmojiPicker from './EmojiPicker';  // Emoji Picker importálása 
import GiftImagePage from './master2';
 
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('supabaseUrl and supabaseKey are required.');
}

const supabase = createClient(supabaseUrl, supabaseKey);


const Vote = () => {
  const { account } = useWeb3React();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false); // Tracks if chat is open
  const [receiver, setReceiver] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false); // State to track focus
  const { t } = useTranslation();
  const [userNumbers, setUserNumbers] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [onlineStatuses, setOnlineStatuses] = useState({});
  const [cooldown, setCooldown] = useState(0);
  const [userList, setUserList] = useState([]);
  const chatBoxRef = useRef(null);
  const location = useLocation();
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [inputValue, setInputValue] = useState(''); // Új állapotváltozó az input mező értékéhez
  const [names, setNames] = useState({});
  const [nameToAddressMap, setNameToAddressMap] = useState({});
  const [filteredUserList, setFilteredUserList] = useState([]);

  const cachedUserData = useRef({});
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [contract, setContract] = useState(null);
  const [contract2, setContract2] = useState(null);
  const adminAddress = '0xEa9C8f2D9d91cF44206F0d869b9cc39897a13Ae1';
  const [image, setImage] = useState(null);  // Állapot a képekhez
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);  // Állapot az emoji pickerhez
  const fileInputRef = useRef(null);  // Referencia a fájl input mezőhöz
  
  const [userOnlineStatus, setUserOnlineStatus] = useState(true);  // New state for online/offline toggle
  const [enlargedImage, setEnlargedImage] = useState(null);  // New state for enlarged image modal
  
  
  const updateOnlineStatus = useCallback(async (status) => {
    if (!account) return;
  
    try {
      const { error } = await supabase
        .from('user_data')
        .update({ online_status: status })
        .eq('account', account);
  
      if (error) {
        console.error('Error updating online status:', error);
      } else {
        console.log('Online status updated successfully');
      }
    } catch (error) {
      console.error('Unexpected error updating online status:', error);
    }
  }, [account]);
  

  useEffect(() => {
    const fetchOnlineStatuses = async () => {
      const newStatuses = {};
      await Promise.all(userList.map(async (user) => {
        newStatuses[user] = await getSenderStatus(user);
      }));
      setOnlineStatuses(newStatuses);
    };
  
    fetchOnlineStatuses();
  }, [userList]);
  
  const getSenderStatusCached = (user) => {
    return onlineStatuses[user] || 'OFFLINE';
  };


  useEffect(() => {
    const fetchUserStatus = async () => {
      if (!account) return;
  
      try {
        const { data, error } = await supabase
          .from('user_data')
          .select('online_status')
          .eq('account', account)
          .single();  // Use single() to get a single record
  
        if (error) {
          console.error('Error fetching online status:', error);
          return;
        }
  
        // Update the online status based on the database value
        setUserOnlineStatus(data?.online_status ?? false);  // Defaults to false if no data
      } catch (error) {
        console.error('Unexpected error fetching online status:', error);
      }
    };
  
    fetchUserStatus();
  }, [account]);  // Run this effect whenever the account changes
  
  const handleReceiverInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  
    if (value.trim().length > 0) {
      // Filter users based on the input value
      const filteredUsers = userList.filter((user) => {
        const name = getSenderName(user).toLowerCase();
        return name.includes(value.toLowerCase()) || user.toLowerCase().includes(value.toLowerCase());
      });
  
      setIsUserSelected(true);
      setFilteredUserList(filteredUsers); // Update the filtered list
    } else {
      // If input is empty, show all users
      setFilteredUserList(userList);
      setIsUserSelected(false);
    }
  };
  
  
  const getUnreadMessagesCount = (user) => {
    return messages.filter(msg => msg.sender === user && msg.receiver === account && !msg.read).length;
  };
  
  const handleReceiverFocus = () => {
    setIsInputFocused(true); // Set focus state to true when input is focused
  };

  // handleReceiverBlur (updated)
const handleReceiverBlur = () => {
  setIsInputFocused(false);

  const resolvedAddress = nameToAddressMap[inputValue.trim()];
  if (resolvedAddress) {
    setReceiver(resolvedAddress);
    setSelectedUser(resolvedAddress);
    setIsUserSelected(true);
    setIsChatOpen(true); // Automatically open the chat
  } else if (inputValue.trim() !== '') {
    setReceiver(inputValue);
    setSelectedUser(inputValue);
    setIsUserSelected(true);
    setIsChatOpen(true); // Automatically open the chat even if the user is not in nameToAddressMap
  } else {
    setIsUserSelected(false);
  }
};

// handleKeyPress (updated)
const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    const resolvedAddress = nameToAddressMap[inputValue.trim()];
    if (resolvedAddress) {
      setReceiver(resolvedAddress);
      setSelectedUser(resolvedAddress);
      setIsUserSelected(true);
      setIsChatOpen(true); // Automatically open the chat
    } else if (inputValue.trim() !== '') {
      setReceiver(inputValue);
      setSelectedUser(inputValue);
      setIsUserSelected(true);
      setIsChatOpen(true); // Automatically open the chat even if the user is not in nameToAddressMap
    } else {
      setIsUserSelected(false);
    }
  }
};

const updateUserList = (messages) => {
  const usersWithMessages = new Set();

  messages.forEach((message) => {
    usersWithMessages.add(message.sender);
    usersWithMessages.add(message.receiver);
  });

  const sortedUsers = Array.from(usersWithMessages).sort((a, b) => {
    const lastMessageA = messages.filter(msg => msg.sender === a || msg.receiver === a).pop()?.timestamp || 0;
    const lastMessageB = messages.filter(msg => msg.sender === b || msg.receiver === b).pop()?.timestamp || 0;
    return new Date(lastMessageB) - new Date(lastMessageA);
  });

  if (!sortedUsers.includes(adminAddress)) {
    sortedUsers.unshift(adminAddress); // Admin hozzáadása, ha még nincs
  }

  setUserList(sortedUsers);
};
  const handleReceiverClick = () => {
    if (inputValue.trim().length === 0) {
      setSelectedUser(null);
      setReceiver(''); // Clear the receiver only if input is empty
      setIsUserSelected(false);
    }
  };  
  
  useEffect(() => {
    if (!account) return;
  
    const fetchAllUserNames = async () => {
      const newNames = {};
      const newMap = {};
  
      try {
        // Lekérjük a neveket a Supabase-ből
        const { data: userData, error } = await supabase.from('user_data').select('account, name');
        if (error) throw error;
  
        userData.forEach(user => {
          newNames[user.account] = user.name;
          newMap[user.name] = user.account;
        });
  
        // Lekérjük a neveket a contractból azokhoz, akik nincsenek a Supabase-ben
        const accountsToFetch = Object.keys(newNames).filter(account => !newNames[account]);
  
        const namePromises = accountsToFetch.map(async account => {
          const name = await contract.getName(account);
          return { account, name };
        });
  
        const resolvedNames = await Promise.all(namePromises);
  
        resolvedNames.forEach(({ account, name }) => {
          if (name) {
            newNames[account] = name;
            newMap[name] = account;
          }
        });
  
        setNames(prev => ({ ...prev, ...newNames }));
        setNameToAddressMap(prev => ({ ...prev, ...newMap }));
      } catch (error) {
        console.error('Error fetching user names:', error);
      }
    };
  
    fetchAllUserNames();
  }, [account, contract]);
  
  
  useEffect(() => {
    let messageChannel;

    const fetchMessages = async () => {
      try {
        const { data, error } = await supabase
          .from('private_messages')
          .select('*')
          .or(`sender.eq.${account},receiver.eq.${account}`)
          .order('timestamp', { ascending: true });

        if (error) {
          console.error('Error fetching messages:', error);
          return;
        }

        setMessages(data);
         // Always set messages to an array
         setMessages(data || []);  // Ensure that if data is null, it's set to an empty array

         const unreadCount = (data || []).filter(msg => msg.receiver === account && !msg.read).length;
         setUnreadMessages(unreadCount);

        const usersWithMessages = new Set();

        data.forEach((message) => {
          usersWithMessages.add(message.sender);
          usersWithMessages.add(message.receiver);
        });

        const sortedUsers = Array.from(usersWithMessages).sort((a, b) => {
          const lastMessageA = data.filter(msg => msg.sender === a || msg.receiver === a).pop()?.timestamp || 0;
          const lastMessageB = data.filter(msg => msg.sender === b || msg.receiver === b).pop()?.timestamp || 0;
          return new Date(lastMessageB) - new Date(lastMessageA);
        });
        
        // Ellenőrizzük, hogy az 'Admin' már benne van-e a listában
        if (!sortedUsers.includes(adminAddress)) {
          sortedUsers.unshift(adminAddress);  // Ha nincs, hozzáadjuk az elejére
        }
        
        setUserList(sortedUsers);
      
        messageChannel = supabase
          .channel('private_messages')
          .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'private_messages' }, payload => {
            const newMessage = payload.new;
            if (newMessage.sender === account || newMessage.receiver === account) {
              setMessages(prevMessages => {
                const updatedMessages = [...prevMessages, newMessage];

                const updatedUserList = new Set([...usersWithMessages, newMessage.sender, newMessage.receiver]);

                setUserList([adminAddress, ...Array.from(updatedUserList).sort((a, b) => {
                  const lastMessageA = updatedMessages.filter(msg => msg.sender === a || msg.receiver === a).pop()?.timestamp || 0;
                  const lastMessageB = updatedMessages.filter(msg => msg.sender === b || msg.receiver === b).pop()?.timestamp || 0;
                  return new Date(lastMessageB) - new Date(lastMessageA);
                })]);

                return updatedMessages;
              });
            }
          })
          .subscribe();

      } catch (error) {
        console.error('Unexpected error fetching messages:', error);
      }
    };

    // Fetch messages immediately when the component mounts
  fetchMessages();

  // Set up an interval to fetch messages every 5 seconds
  const intervalId = setInterval(() => {
    fetchMessages();
  }, 5000); // 5 seconds interval

  // Cleanup the interval and channel on component unmount
  return () => {
    clearInterval(intervalId);
    if (messageChannel) {
      supabase.removeChannel(messageChannel);
    }
  };
}, [account]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, selectedUser]);

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [cooldown]);

  const handleDeleteMessage = async (msg) => {
    if (!window.confirm('Are you sure you want to delete this message?')) {
      return;
    }
  
    try {
      const { error } = await supabase
        .from('private_messages')
        .delete()
        .eq('timestamp', msg.timestamp)  // Feltételezve, hogy a timestamp egyedi azonosító
        .eq('sender', msg.sender)
        .eq('receiver', msg.receiver);
  
      if (error) {
        console.error('Error deleting message:', error.message);
        return;
      }
  
      // Töröljük az adott üzenetet a helyi állapotból
      setMessages(prevMessages => prevMessages.filter(m => m.timestamp !== msg.timestamp));
    } catch (error) {
      console.error('Unexpected error deleting message:', error);
    }
  };
  

  const handleDeleteMessages = async (e, user) => {
    e.stopPropagation();  // Megakadályozza a UserButton más eseményeit
  
    if (!window.confirm(`Are you sure you want to delete all messages with ${getSenderName(user)}?`)) {
      return;
    }
  
    try {
      // Supabase-ből törlünk minden üzenetet, ahol a bejelentkezett felhasználó vagy a kiválasztott felhasználó a feladó/címzett
      const { error } = await supabase
        .from('private_messages')
        .delete()
        .or(`and(sender.eq.${account},receiver.eq.${user}),and(sender.eq.${user},receiver.eq.${account})`);
  
      if (error) {
        console.error('Error deleting messages:', error.message);
        return;
      }
  
      // Töröljük az üzeneteket a helyi állapotból
      setMessages(prevMessages => 
        prevMessages.filter(msg => !(msg.sender === user || msg.receiver === user))
      );
      
      alert('All messages with this user have been deleted.');
    } catch (error) {
      console.error('Unexpected error deleting messages:', error);
    }
  };
  
  
  useEffect(() => {
    if (!selectedUser) return;
  
    const markMessagesAsRead = async () => {
      try {
        const { error } = await supabase
          .from('private_messages')
          .update({ read: true })
          .eq('sender', selectedUser)
          .eq('receiver', account)
          .eq('read', false);
  
        if (error) {
          console.error('Error marking messages as read:', error);
          return;
        }
  
        // Ensure prevMessages is an array before filtering
        setMessages(prevMessages => 
          Array.isArray(prevMessages)
            ? prevMessages.map(msg =>
                msg.sender === selectedUser && msg.receiver === account ? { ...msg, read: true } : msg
              )
            : []
        );
  
        // Recalculate unread messages, ensuring prevMessages is an array
        setUnreadMessages(prevMessages =>
          Array.isArray(prevMessages)
            ? prevMessages.filter(msg => msg.receiver === account && !msg.read).length
            : 0
        );
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    };
  
    markMessagesAsRead();
  }, [selectedUser, account]);
  
  
  const getSenderStatus = async (user) => {
    try {
      const { data, error } = await supabase
        .from('user_data')
        .select('online_status')
        .eq('account', user)
        .single();  // Use single() to get a single record
  
      if (error) {
        console.error(`Error fetching online status for ${user}:`, error);
        return 'OFFLINE';
      }
  
      return data?.online_status ? 'ONLINE' : 'OFFLINE';
    } catch (error) {
      console.error('Unexpected error fetching online status:', error);
      return 'OFFLINE';
    }
  };
  
  
  const handleSend = async () => {
    if (cooldown > 0) return;
  
    const currentReceiver = selectedUser || receiver;
    const resolvedReceiver = nameToAddressMap[currentReceiver] || currentReceiver;
  
    if (resolvedReceiver.trim() === '' || (newMessage.trim() === '' && !image) || resolvedReceiver === account) return;
  
    const message = {
      text: newMessage.trim(),  // Elküldjük a szöveget, ha van
      sender: account,
      receiver: resolvedReceiver,
      image,  // Elküldjük a képet, ha van (az `image` state-ből)
      timestamp: new Date().toISOString(),
    };
  
    try {
      const { error } = await supabase
        .from('private_messages')
        .insert([message]);
  
      if (error) {
        console.error('Hiba történt az üzenet küldésekor:', error.message);
        return;
      }
  
      // Töröljük a szöveget és a képet a state-ből a küldés után
      setNewMessage('');
      setImage(null);
      setCooldown(15);  // Cooldown idő a következő üzenet előtt
    } catch (error) {
      console.error('Váratlan hiba történt az üzenet küldésekor:', error);
    }
  };
  

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      if (file.size > 1048576) {  // 1 MB méretkorlát
        alert('The file size must be less than 1 MB.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (!account || userList.length === 0) return;
  
    const fetchNamesAndNumbers = async () => {
      const newNames = {};
      const newNumbers = {};
      const newMap = {};
  
      await Promise.all(userList.map(async (user) => {
        if (cachedUserData.current[user]) {
          const { name, number } = cachedUserData.current[user];
          newNames[user] = name;
          newNumbers[user] = number;
          newMap[name] = user;
          return;
        }
  
        try {
          const { data: userData, error } = await supabase
            .from('user_data')
            .select('name, number')
            .eq('account', user)
            .single();
  
          if (error) {
            console.error(`Error fetching data for ${user}:`, error);
          } else if (userData) {
            newNames[user] = userData.name;
            newNumbers[user] = userData.number;
            newMap[userData.name] = user;
            cachedUserData.current[user] = { name: userData.name, number: userData.number };
          }
        } catch (error) {
          console.error(`Unexpected error fetching data for ${user}:`, error);
        }
      }));
  
      setNames(prev => ({ ...prev, ...newNames }));
      setNameToAddressMap(prev => ({ ...prev, ...newMap }));
      setUserNumbers(prev => ({ ...prev, ...newNumbers }));
    };
  
    fetchNamesAndNumbers();
  }, [userList, account]);
  

  const getActiveIndex = (pathname: string): number => {
    if (
      pathname.includes('/lockedstaking/all') ||
      pathname.includes('/lockedstaking/history')
    ) {
      return 0;
    }
    return 1;
  };

  const getSenderName = (sender) => {
    if (sender === adminAddress) {
      return 'Admin';
    }
    const name = names[sender];
    return name ? `${name}` : `${sender.substring(0, 5)}...${sender.substring(sender.length - 5)}`;
  };

  const getSenderImage = (sender) => {
    const userNumber = userNumbers[sender];
  
    if (userNumber) {
      let formattedNumber;
      
      // Ha a szám kisebb, mint 10, kétjegyűre formázzuk (01, 02, stb.)
      if (userNumber < 10) {
        formattedNumber = userNumber.toString().padStart(2, '0');
      } 
      // Ha a szám 10 vagy nagyobb, háromjegyűre formázzuk (011, 025, stb.)
      else {
        formattedNumber = userNumber.toString().padStart(3, '0');
      }
  
      return `images/pixel/${formattedNumber}.jpg`;
    }
  
    return 'images/pixel/200.png';  // Alapértelmezett kép, ha nincs szám
  };
  

  const getMessagesForUser = user => {
    return messages.filter(msg =>
      (msg.sender === user && msg.receiver === account) ||
      (msg.sender === account && msg.receiver === user)
    );
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp); // Eredeti UTC idő
    const timeString = date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    const dateString = date.toLocaleDateString(navigator.language, {
      month: 'long',     // Hónap neve
      day: 'numeric',    // Nap
    });
    return `${timeString}, ${dateString}`;
  };
  

  const handleImageClick = (src) => {
    setEnlargedImage(src);  // Set the image source to enlarged image modal state
  };

  const handleModalClose = () => {
    setEnlargedImage(null);  // Close the modal
  };

  function handleStatusToggle(event) {
    const isChecked = event.target.checked;
    setUserOnlineStatus(isChecked);  // Update the user online status state
    updateOnlineStatus(isChecked);   // Call the function to update the status in the database
  }
  

  return (
    <>
   {account ? (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      style={{
        padding: '20px',
        backgroundColor: '#000',
        borderRadius: '10px', // Border radius hozzáadása
        width: '100%',        // 100%-os szélesség
        height: '70px',        // 100%-os szélesség
        maxWidth: '800px',    // Max szélesség 800px
        margin: '20px auto',   // Középre igazítás és margin-top 20px
      }}
    >
      {/* VizslaChat cím középre igazítva a bal oldalon */}
      <Title style={{ fontSize: '28px', fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>
        VizslaChat
      </Title>

      {/* Nyelvválasztó és privát chat/setting gombok egy sorban */}
      <Flex alignItems="center">
        {/* Private Chat gomb (átnevezve PrivateChat-re) */}
        <Link to="/chat" style={{ textDecoration: 'none' }}>
          <Button
            style={{
              backgroundColor: 'transparent',
              color: '#fff',
              border: '1px solid gold',
              padding: '5px 10px',
              marginRight: '5px',
              cursor: 'pointer',
            }}
          >
            PublicChat
          </Button>
        </Link>

        {/* Settings felirat (fogaskerék helyett) */}
        <Link to="/settings" style={{ textDecoration: 'none' }}>
          <Button
            style={{
              backgroundColor: 'transparent',
              color: 'gold',
              border: 'none',
              padding: '5px',
              cursor: 'pointer',
            }}
          >
            ⚙️
          </Button>
        </Link>
      </Flex>
    </Flex>
     ) : null} {/* Amikor nincs account, nem renderel semmit */}

      <Container>
      {account ? (
        <>
          {/* Sidebar and input field only shown when chat is NOT open */}
          {!isChatOpen && (
            <>
              <Sidebar>
                <h3>Users</h3>
                <InputWrapper style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
  <Input
    type="text"
    placeholder="Send Message (Address or Name)"
    value={inputValue}
    onChange={handleReceiverInputChange}
    onClick={handleReceiverClick}
    onFocus={handleReceiverFocus}
    onBlur={handleReceiverBlur}
    onKeyPress={handleKeyPress}
    style={{ paddingRight: '60px' }} // Add extra padding to make space for Enter text
  />

  {/* Enter text positioned outside the Input on the right */}
  <EnterText
    show={inputValue.trim().length > 0}
    role="button"
    tabIndex="0"
    onMouseDown={(e) => {
      e.preventDefault();  // Prevent losing focus from input
    }}
    onClick={() => {
      if (inputValue.trim().length > 0) {
        handleKeyPress({ key: 'Enter' });  // Simulate Enter keypress
      }
    }}
    style={{
      position: 'absolute',
      right: '5px',  // Positioning outside the input, 10px from the right edge
      top: '42%',
      transform: 'translateY(-50%)',  // Center vertically
      cursor: 'pointer',
      color: 'gold',  // You can style this to look clickable
    }}
  >
    Enter
  </EnterText>
</InputWrapper>

                <Flex alignItems="center" mb="10px">
                  <Toggle
                    checked={userOnlineStatus}  // Online/offline toggle state
                    onChange={() => {
                      setUserOnlineStatus(!userOnlineStatus);  // Toggle user online status
                      updateOnlineStatus(!userOnlineStatus);   // Update in the database
                    }}
                    scale="sm"
                  />
                  <span style={{ marginLeft: '10px', color: userOnlineStatus ? '#80efb4' : '#ea3323' }}>
                    {userOnlineStatus ? 'Online' : 'Offline'}
                  </span>

                  {/* Small Button that navigates to /onlineboard */}
                  <Button
                    as={Link}
                    to="/userlist"
                    scale="sm"
                    variant="secondary"
                    ml="10px"
                    style={{ padding: '4px 8px', fontSize: '12px' }}
                  >
                    UserList
                  </Button>
                  <Button
    scale="sm"
    variant="gold"
    ml="10px"
    style={{ padding: '4px 8px', fontSize: '12px' }}
    onClick={() => {
      setSelectedUser(adminAddress);  // Select the Admin user
      setIsUserSelected(true);        // Mark the user as selected
      setIsChatOpen(true);            // Open the chatbox
    }}
  >
    Help
  </Button>
  <GiftImagePage/>

                </Flex>

                <UserList>
  {/* Use filteredUserList if available, otherwise use userList */}
  {(filteredUserList.length > 0 ? filteredUserList : userList)
    .filter(user => user === adminAddress || getMessagesForUser(user).length > 0)
    .map(user => (
      <UserListItem key={user} isActive={selectedUser === user} isTop10={false}>
        <UserButton
          onClick={() => {
            setSelectedUser(user);
            setIsUserSelected(true);
            setIsChatOpen(true); // Open the chat view
          }}
        >
          <div style={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
            {/* Left side: Avatar, status, and name */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={getSenderImage(user)} alt="user" style={{ marginRight: '8px' }} />
              <span
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: getSenderStatusCached(user) === 'ONLINE' ? '#4bf2af' : 'red',
                  borderRadius: '50%',
                  marginRight: '8px',
                }}
              />
              <span style={{ fontSize: '14px', fontWeight: 'bold', color: user === adminAddress ? 'gold' : 'white', }}>{getSenderName(user)}</span>
            </div>
             {/* Unread messages */}
          {getUnreadMessagesCount(user) > 0 && (
            <span style={{ marginLeft: '10px', color: '#4bf2af' }}>
              + {getUnreadMessagesCount(user)} messages
            </span>
          )}
            {/* Right side: Delete button */}
            <Button
              onClick={(e) => handleDeleteMessages(e, user)} 
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '2px 8px', fontSize: '10px', lineHeight: '1', height: '18px', width: '18px', borderRadius: '3px',
                cursor: 'pointer',
                marginRight: '10px',
                marginLeft: 'auto',
                alignSelf: 'center',
              }}
            >
              X
            </Button>
          </div>    
        </UserButton>
      </UserListItem>
    ))}
</UserList>

              </Sidebar>
            </>
          )} 

          {/* Chatbox view shown when a user is selected */}
          {isChatOpen && (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
              <Button
                onClick={() => {
                  setIsChatOpen(false); // Close chat view
                  setSelectedUser(null); // Clear selected user
                }}
                style={{
                  marginBottom: '10px',
                  padding: '8px',
                  fontSize: '16px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: 'gold',
                  textAlign: 'left',
                }}
              >
                ← Back to User List
              </Button>
              {/* Display the selected user's avatar and name */}
              {selectedUser && (
  <div
    style={{
      display: 'flex',
      alignItems: 'center', // Center everything horizontally
      justifyContent: 'center', // Vertically center the content
      marginBottom: '10px',
      textAlign: 'center', // Center text inside the container
    }}
  >
    <Avatar src={getSenderImage(selectedUser)} alt="user" style={{ marginBottom: '10px' }} />
    <span style={{ fontSize: '16px', fontWeight: 'bold', color: 'white' }}>
      {getSenderName(selectedUser)}
    </span>
  </div>
)}

              <ChatBox ref={chatBoxRef}>
                {selectedUser ? (
                  getMessagesForUser(selectedUser).length > 0 ? (
                    getMessagesForUser(selectedUser).map(msg => (
                      <MessageContainer key={msg.timestamp}>
                        <Avatar src={getSenderImage(msg.sender)} alt="sender" />
                        <Message>
                          <b>{getSenderName(msg.sender)}:</b> {msg.text}
                          {msg.image && (
                            <Button
                              style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }}
                              onClick={() => handleImageClick(msg.image)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  handleImageClick(msg.image);
                                }
                              }}
                            >
                              <img
                                src={msg.image}
                                alt="sent"
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                  objectFit: 'contain',
                                  marginTop: '40px'
                                }}
                              />
                            </Button>
                          )}
                          <Timestamp>{formatTimestamp(msg.timestamp)}</Timestamp>
                        </Message>
                        <Button
                          onClick={() => handleDeleteMessage(msg)}
                          style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '0',
                            alignSelf: 'center',
                          }}
                        >
                          🗑️
                        </Button>
                      </MessageContainer>
                    ))
                  ) : (
                    <Message>No messages yet.</Message>
                  )
                ) : (
                  <Message>No user selected</Message>
                )}
              </ChatBox>

              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Input
                  type="text"
                  placeholder={`Write a message${cooldown > 0 ? ` (${cooldown}s)` : ''}`}
                  value={newMessage}
                  onChange={e => setNewMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                   onKeyDown={(e) => {
    if (e.key === 'Enter' && !e.shiftKey) { // Shift+Enter for new lines
      e.preventDefault(); // Prevent default behavior of creating a new line
      handleSend(); // Send message
    }
  }}
                  disabled={cooldown > 0}
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <SendButton onClick={handleSend} disabled={cooldown > 0}>Send</SendButton>
                  <Button mr="5px" ml="5px" onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}>
                    😊
                  </Button>
                  <Button onClick={() => fileInputRef.current.click()}>
                    📤
                  </Button>
                </div>
                {image && (
  <div
    style={{
      position: 'relative',
      display: 'inline-block',
      marginTop: '10px',
      padding: '10px',
      marginBottom: '-80px', // Explicitly setting margin bottom to 0
      backgroundColor: '#000', // Hátteret adunk
      borderRadius: '8px', // Lekerekített sarkok
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Enyhe árnyék
    }}
  >
    <img
      src={image}
      alt="Előnézet"
      style={{ width: '100px', height: '100px' }} // Kép mérete és kerekítés
    />
    <Button
      onClick={() => setImage(null)} // Törlés lehetősége
      style={{
        position: 'absolute',
        top: '5px',
        right: '5px',
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        padding: '2px 6px',
        fontSize: '12px',
        borderRadius: '10%', // Kör alakú gomb
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Árnyék a gombnak
      }}
    >
      X
    </Button>
  </div>
)}
                {emojiPickerVisible && (
                  <EmojiPicker 
                    setNewMessage={setNewMessage}
                    newMessage={newMessage}
                    handleSetImage={setImage}  // Pass handleSetImage to set the image when selected
                    />
                )}



                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <ConnectWalletButton />
      )}
    </Container>
       
      {/* Enlarged Image Modal */}
      {enlargedImage && (
       <div
       style={{
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, 0.8)',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         zIndex: 1000,
       }}
       onClick={handleModalClose}
       role="button"  // Explicitly set the role
       tabIndex="0"  // Make it focusable
       onKeyDown={(e) => {
         if (e.key === 'Enter' || e.key === ' ') {
           handleModalClose();  // Handle keyboard interaction
         }
       }}
     >     
          <img
            src={enlargedImage}
            alt="Enlarged"
            style={{ maxWidth: '90%', maxHeight: '90%', cursor: 'pointer' }}
          />
        </div>
      )} 
    </>
  );
};

export default Vote;


