import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import { Button, Input } from '@pancakeswap/uikit';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const ToplistContainer = styled.div`
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #1e1e2e;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #eaeaea;
`;

const TopListTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 0 0 2.5px gold, 0 0 5px gold;
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
  background-color: #1f2833;
  color: #fff;
  border: 1px solid gold;

  &::placeholder {
    color: gold;
  }
`;

const UserItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #16161f;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;

const UserName = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const UserScore = styled.span`
  font-size: 18px;
  color: #eaeaea;
`;

const CountdownContainer = styled.div`
  font-size: 18px;
  color: gold;
  margin: 20px 0;
  padding: 10px;
  background-color: #24343a;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;

const BackButton = styled(Button)`
  margin-bottom: 20px;
  background-color: #e6ac00;
  color: #fff;
  &:hover {
    background-color: #d4a017;
  }
`;

const fetchTopList = async () => {
  const { data, error } = await supabase
    .from('quiz_scores')
    .select('account, best_score')
    .order('best_score', { ascending: false });

  if (error) {
    console.error('Error fetching quiz scores:', error);
    return [];
  }

  const enrichedData = await Promise.all(
    data.map(async (user) => {
      const { data: userData, error: userError } = await supabase
        .from('user_data')
        .select('name')
        .eq('account', user.account)
        .single();

      const name = userError || !userData ? `${user.account.substring(0, 5)}...${user.account.substring(user.account.length - 5)}` : userData.name;

      return {
        ...user,
        name,
      };
    })
  );

  return enrichedData;
};

const Toplist = () => {
  const [topList, setTopList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTopList();
      setTopList(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const targetDate = new Date('2025-03-31T00:00:00');
    const countdown = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        clearInterval(countdown);
        setTimeLeft('The competition has ended.');
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const filteredTopList = topList.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.account.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <ToplistContainer>
      <Link to="/quiz">
        <BackButton>&lt; Back to Quiz</BackButton>
      </Link>
      <TopListTitle>Top List</TopListTitle>
      <p>All users scoring above 30 points earn 3 VIZSLASWAP tokens per point.</p>
      <CountdownContainer>
        <div>Time left until the competition ends:</div>
        <div>{timeLeft}</div>
      </CountdownContainer>
      <StyledInput
        placeholder="Search by name or address"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <ul>
        {filteredTopList.map((user) => (
          <UserItem key={user.account}>
            <UserName>{user.name}</UserName>
            <UserScore>{user.best_score} points</UserScore>
            <UserScore>{user.best_score * 3} VIZSLASWAP</UserScore>
          </UserItem>
        ))}
      </ul>
    </ToplistContainer>
  );
};

export default Toplist;
