import React, { lazy, Suspense } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { ResetCSS } from '@pancakeswap/uikit';
import BigNumber from 'bignumber.js/bignumber';
import useEagerConnect from 'hooks/useEagerConnect';
import { usePollBlockNumber } from 'state/block/hooks';
import { usePollCoreFarmData } from 'state/farms/hooks';
import { useFetchProfile } from 'state/profile/hooks';
import { DatePickerPortal } from 'components/DatePicker';
import Toplist from 'views/Profile/Toplist';
import ToplistBlack from 'views/BlackJack/top';
import Top2 from 'views/Queez/top';
import Top3 from 'views/SpinWheel/top';
import BlackJack from 'views/BlackJack/black';
import SpinWheel from 'views/SpinWheel/SpinWheel';
import VoteClosed from 'views/Invite';
import Queez from 'views/Queez/index'; 
import Master from 'views/Vote/master';
import Chart from 'views/Chart';
import Chart2 from 'views/Chart/index2';
import Chart3 from 'views/Chart/Partnership3';
import Chart4 from 'views/Chart/Partnership4';
import Chart5 from 'views/Chart/Partnership5';
import Chart6 from 'views/Chart/Partnership6';
import Chart7 from 'views/Chart/Partnership7';
import Chart8 from 'views/Chart/Partnership8';
import Chart9 from 'views/Chart/Partnership9';
import Chart10 from 'views/Chart/Partnership10';
import Chart11 from 'views/Chart/Partnership11';
import Chart12 from 'views/Chart/Partnership12';
import Chart13 from 'views/Chart/Partnership13';
import Chart14 from 'views/Chart/Partnership14';
import Chart15 from 'views/Chart/Partnership15';
import Chart16 from 'views/Chart/Partnership16';
import Chart17 from 'views/Chart/Partnership17';
import Chart18 from 'views/Chart/Partnership18';
import Chart19 from 'views/Chart/Partnership19';
import Chart20 from 'views/Chart/Partnership20';
import Chart21 from 'views/Chart/Partnership21';
import Chart22 from 'views/Chart/Partnership22';
import Chart23 from 'views/Chart/Partnership23';
import Chart24 from 'views/Chart/Partnership24';
import Chart25 from 'views/Chart/Partnership25';
import Chart26 from 'views/Chart/Partnership26';
import Chart27 from 'views/Chart/Partnership27';
import UserList from 'views/ActiveList';
import Limit from 'views/LimitOrders';
import { ToastListener } from 'contexts/ToastsContext';
import RedirectPage from './views/Invite/RedirectPage';
import GlobalStyle from './style/Global';
import Menu from './components/Menu';
import PageLoader from './components/Loader/PageLoader';
import EasterEgg from './components/EasterEgg';
import history from './routerHistory';
// Views included in the main bundle
import Pools from './views/Pools';
import FarmPools from './views/FarmPools';
import Vote from './views/Vote';
import Vote2 from './views/Vote2';
import Donation from './views/Donation';
import ApeSwap from './views/ApeSwap';
import RadioShack from './views/RadioShack';
import Biswap from './views/Biswap';
import Babydoge from './views/BabyDoge';
import Swap from './views/Swap';
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects';
import {
  RedirectDuplicateTokenIds2,
  RedirectOldAddLiquidityPathStructure2,
  RedirectToAddLiquidity2,
} from './views/AddLiquidity2/redirects';
import {
  RedirectDuplicateTokenIds3,
  RedirectOldAddLiquidityPathStructure3,
  RedirectToAddLiquidity3,
} from './views/AddLiquidity4/redirects';
import {
  RedirectDuplicateTokenIds4,
  RedirectOldAddLiquidityPathStructure4,
  RedirectToAddLiquidity4,
} from './views/AddLiquidity5/redirects';
import {
  RedirectDuplicateTokenIds5,
  RedirectOldAddLiquidityPathStructure5,
  RedirectToAddLiquidity5,
} from './views/AddLiquidity6/redirects';
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects';
import RedirectOldRemoveLiquidityPathStructure2 from './views/RemoveLiquidity2/redirects';
import RedirectOldRemoveLiquidityPathStructure4 from './views/RemoveLiquidity4/redirects';
import RedirectOldRemoveLiquidityPathStructure5 from './views/RemoveLiquidity5/redirects';
import RedirectOldRemoveLiquidityPathStructure6 from './views/RemoveLiquidity6/redirects';
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects';

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'));
const Listing = lazy(() => import('./views/Listing'));
const Partnership = lazy(() => import('./views/Partnership'));
const ListingForm = lazy(() => import('./views/ListingForm'));
const Farms = lazy(() => import('./views/Farms'));
const NotFound = lazy(() => import('./views/NotFound'));
const Profile = lazy(() => import('./views/Profile'));
const AddLiquidity = lazy(() => import('./views/AddLiquidity'));
const AddLiquidity2 = lazy(() => import('./views/AddLiquidity2'));
const AddLiquidity4 = lazy(() => import('./views/AddLiquidity4'));
const AddLiquidity5 = lazy(() => import('./views/AddLiquidity5'));
const AddLiquidity6 = lazy(() => import('./views/AddLiquidity6'));
const Liquidity = lazy(() => import('./views/Pool'));
const Liquidity2 = lazy(() => import('./views/Pool2'));
const Liquidity4 = lazy(() => import('./views/Pool4'));
const Liquidity5 = lazy(() => import('./views/Pool5'));
const Liquidity6 = lazy(() => import('./views/Pool6'));
const PoolFinder = lazy(() => import('./views/PoolFinder'));
const PoolFinder2 = lazy(() => import('./views/PoolFinder2'));
const PoolFinder4 = lazy(() => import('./views/PoolFinder4'));
const PoolFinder5 = lazy(() => import('./views/PoolFinder5'));
const PoolFinder6 = lazy(() => import('./views/PoolFinder6'));
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'));
const RemoveLiquidity2 = lazy(() => import('./views/RemoveLiquidity2'));
const RemoveLiquidity4 = lazy(() => import('./views/RemoveLiquidity4'));
const RemoveLiquidity5 = lazy(() => import('./views/RemoveLiquidity5'));
const RemoveLiquidity6 = lazy(() => import('./views/RemoveLiquidity6'));
const WalletTransactions = lazy(() => import('./views/WalletTransactions'));

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  usePollBlockNumber();
  useEagerConnect();
  useFetchProfile();
  usePollCoreFarmData();

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route exact path="/partnership">
              <Listing />
            </Route>
            <Route exact path="/PrivateChat">
              <Vote />
            </Route>
            <Route exact path="/quiz">
              <Queez />
            </Route>
            <Route exact path="/quiztoplist">
              <Top2 />
            </Route>  
                  <Route exact path="/sugartoplist">
              <Top3 />
            </Route>
            <Route exact path="/Settings">
              <Vote2 />
            </Route>
            <Route path="/ref">
  <RedirectPage />
</Route>
<Route path="/toplist">
  <Toplist />
</Route>
            <Route exact path="/referral">
              <VoteClosed />
            </Route>
            <Route exact path="/blackjacktoplist">
              <ToplistBlack />
            </Route>
            <Route exact path="/sugarvizsla">
              <SpinWheel />
            </Route>
            <Route exact path="/blackjack">
              <BlackJack />
            </Route>
            <Route exact path="/donation"> 
              <Donation />
            </Route> 
            <Route exact path="/partnership">
              <Partnership />
            </Route>
            <Route exact path="/listingform">
              <ListingForm />
            </Route>
            <Route path="/mining">
              <Farms />
            </Route> 
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/be2mining">
              <FarmPools />
            </Route>
            {/* Charts */}
            <Route path="/charts">
              <Chart />
            </Route> 
            <Route path="/HVI-0xde619a9e0eeeaa9f8cd39522ed788234837f3b26">
              <Chart2 />
            </Route>
            <Route path="/VIZSLASWAP-0xadaae082237cb1772c9e079db95c117e6dd0c5af">
              <Chart3 />
            </Route>
            <Route path="/LAMEA-0xaa44051bdd76E251aab66dbbE82A97343B4D7DA3">
              <Chart4 />
            </Route>
            <Route path="/LGC-0x3496212eC43Cc49F5151ec4405efD4975e036f89">
              <Chart5 />
            </Route>
            <Route path="/BVC-0x2dEA12c4f08074032a9997F0a199b04aF0fC0392">
              <Chart6 />
            </Route>
            <Route path="/BNB-0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c">
              <Chart7 />
            </Route> 
            <Route path="/BTCB-0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c">
              <Chart8/>
            </Route>
            <Route path="/ETH-0x2170Ed0880ac9A755fd29B2688956BD959F933F8">
              <Chart9 />
            </Route>  
            <Route path="/LTC-0x4338665CBB7B2485A8855A139b75D5e34AB0DB94">
              <Chart10 />
            </Route>
            <Route path="/SHIB-0x2859e4544C4bB03966803b044A93563Bd2D0DD4D">
              <Chart11 />
            </Route>
            <Route path="/BABYVIZSLA-0xfB19F247F1f22Cef1F0384535C779c664632cB6F">
              <Chart12 />
            </Route>
            <Route path="/EXZO-0xF8fC63200e181439823251020d691312FDcF5090">
              <Chart13 />
            </Route>
            <Route path="/BUSD-0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56">
              <Chart14 />
            </Route>
            <Route path="/ACE-0xFAaA87943bFca6D97434bE3d26C589647FEA4375">
              <Chart15 />
            </Route>
            <Route path="/FPS-0x213503534d927424A5cdf6d580E9Fd408be9337a">
              <Chart16 />
            </Route>
            <Route path="/ATALIRA-0x8604d31F3683A22b328CF6F974A34233c3494ccf">
              <Chart17 />
            </Route>
            <Route path="/BTNTY-0xF15c8d610E02602caAA9959BF5ecfEb8B3D229bE">
              <Chart18 />
            </Route>
            <Route path="/BUILD-0x83b27De2Fca046FA63a11C7Ce7743DE33Ec58822">
              <Chart19 />
            </Route>
            <Route path="/CUBY-0x488907d99cE2ac919A47AcFac896e825Fd23598f">
              <Chart20 />
            </Route>
            <Route path="/ETC-0x3d6545b08693daE087E957cb1180ee38B9e3c25E">
              <Chart21 />
            </Route>
            <Route path="/FartCoin-0x88b985007d714d1578bccdec2303212c14946cdc">
              <Chart22 />
            </Route>
            <Route path="/LIRA-0xA80a006A48dc7203EB3AA7E0b3816918d242CFC4">
              <Chart23 />
            </Route>
            <Route path="/SKELETON-0xb0688E82D162Df5288A0D986DfFd4CF80AFb7897">
              <Chart24 />
            </Route>
            <Route path="/TRIP-0x15a0Ff684918B20487f2a68d6816e694BB05E471">
              <Chart25 />
            </Route>
            <Route path="/USDT-0x55d398326f99059fF775485246999027B3197955">
              <Chart26 />
            </Route>
            <Route path="/BABY-0xA4E26Bd6DCBa9021DCd3A1159BA52e97CD770b8a">
              <Chart27 />
            </Route>
            <Route path="/userlist">
              <UserList />
            </Route>
            <Route path="/gift">
              <Master />
            </Route>
            <Route path="/activity">
              <WalletTransactions />
            </Route>
            <Route path="/limit-orders">
              <Limit />
            </Route>
            <Route path="/chat">
              <Profile />
            </Route> 
            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            <Route exact strict path="/swap">
              <Swap />
            </Route>
            <Route exact strict path="/apeswap">
              <ApeSwap />
            </Route>
            <Route exact strict path="/radioshack">
              <RadioShack />
            </Route>
            <Route exact strict path="/biswap">
              <Biswap />
            </Route>
        
            <Route exact strict path="/babydogeswap">
              <Babydoge />
            </Route>
            <Route exact strict path="/swap/:outputCurrency">
              <RedirectToSwap />
            </Route>
            <Route exact strict path="/send">
              <RedirectPathToSwapOnly />
            </Route>
            <Route exact strict path="/find">
              <PoolFinder />
            </Route>
            <Route exact strict path="/apefind">
              <PoolFinder2 />
            </Route>
            <Route exact strict path="/radiofind">
              <PoolFinder4 />
            </Route>
            <Route exact strict path="/biswapfind">
              <PoolFinder5 />
            </Route>
            <Route exact strict path="/babydogefind">
              <PoolFinder6 />
            </Route>
            <Route exact strict path="/liquidity">
              <Liquidity />
            </Route>
            <Route exact strict path="/apeliquidity">
              <Liquidity2 />
            </Route>
            <Route exact strict path="/radioliquidity">
              <Liquidity4 />
            </Route>
            <Route exact strict path="/biswapliquidity">
              <Liquidity5 />
            </Route>
            <Route exact strict path="/babydogeliquidity">
              <Liquidity6 />
            </Route>
            <Route exact strict path="/create">
              <RedirectToAddLiquidity />
            </Route>
            <Route exact strict path="/createape">
              <RedirectToAddLiquidity2 />
            </Route>
            <Route exact strict path="/createradio">
              <RedirectToAddLiquidity3 />
            </Route>
            <Route exact strict path="/createbiswap">
              <RedirectToAddLiquidity4 />
            </Route>
            <Route exact strict path="/createbabydoge">
              <RedirectToAddLiquidity5 />
            </Route>
            <Route exact path="/add">
              <AddLiquidity />
            </Route>
            <Route exact path="/addapeliquidity">
              <AddLiquidity2 />
            </Route>
            <Route exact path="/addradioliquidity">
              <AddLiquidity4 />
            </Route>
            <Route exact path="/addbiswapliquidity">
              <AddLiquidity5 />
            </Route>
            <Route exact path="/addbabydogeliquidity">
              <AddLiquidity6 />
            </Route>
            <Route exact path="/add/:currencyIdA">
              <RedirectOldAddLiquidityPathStructure />
            </Route>
            <Route exact path="/add/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds />
            </Route>
            <Route exact path="/addapeliquidity/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds2 />
            </Route>
            <Route exact path="/addradioliquidity/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds3 />
            </Route>
            <Route exact path="/addbiswapliquidity/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds4 />
            </Route>
            <Route exact path="/addbabydogeliquidity/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds5 />
            </Route>
            <Route exact path="/create">
              <AddLiquidity />
            </Route>
            <Route exact path="/createape">
              <AddLiquidity2 />
            </Route>
            <Route exact path="/createradio">
              <AddLiquidity4 />
            </Route>
            <Route exact path="/createbiswap">
              <AddLiquidity5 />
            </Route>
            <Route exact path="/createbabydoge">
              <AddLiquidity6 />
            </Route>
            <Route exact path="/create/:currencyIdA">
              <RedirectOldAddLiquidityPathStructure2 />
            </Route>
            <Route exact path="/create/:currencyIdA">
              <RedirectOldAddLiquidityPathStructure3 />
            </Route>
            <Route exact path="/create/:currencyIdA">
              <RedirectOldAddLiquidityPathStructure4 />
            </Route>
            <Route exact path="/create/:currencyIdA">
              <RedirectOldAddLiquidityPathStructure5 />
            </Route>
            <Route exact path="/create/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds />
            </Route>
            <Route exact path="/createape/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds2 />
            </Route>
            <Route exact path="/createradio/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds3 />
            </Route>
            <Route exact path="/createbiswap/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds4 />
            </Route>
            <Route exact path="/createbabydoge/:currencyIdA/:currencyIdB">
              <RedirectDuplicateTokenIds5 />
            </Route>
            <Route exact strict path="/remove/:tokens">
              <RedirectOldRemoveLiquidityPathStructure />
            </Route>
            <Route exact strict path="/removeape/:tokens">
              <RedirectOldRemoveLiquidityPathStructure2 />
            </Route>
            <Route exact strict path="/removeradio/:tokens">
              <RedirectOldRemoveLiquidityPathStructure4 />
            </Route>
            <Route exact strict path="/removebiswap/:tokens">
              <RedirectOldRemoveLiquidityPathStructure5 />
            </Route>
            <Route exact strict path="/removebabydoge/:tokens">
              <RedirectOldRemoveLiquidityPathStructure6 />
            </Route>
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB">
              <RemoveLiquidity />
            </Route>
            <Route exact strict path="/removeape/:currencyIdA/:currencyIdB">
              <RemoveLiquidity2 />
            </Route>
            <Route exact strict path="/removeradio/:currencyIdA/:currencyIdB">
              <RemoveLiquidity4 />
            </Route>
            <Route exact strict path="/removebiswap/:currencyIdA/:currencyIdB">
              <RemoveLiquidity5 />
            </Route>
            <Route exact strict path="/removebabydoge/:currencyIdA/:currencyIdB">
              <RemoveLiquidity6 />
            </Route>

            {/* Redirect */}
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/pool2">
              <Redirect to="/apeliquidity" />
            </Route>
            <Route path="/pool4">
              <Redirect to="/radioliquidity" />
            </Route>
            <Route path="/pool5">
              <Redirect to="/biswapliquidity" />
            </Route>
            <Route path="/pool6">
              <Redirect to="/babydogeliquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools/all" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools/all" />
            </Route>

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  );
};

export default React.memo(App);
