interface LevelSettings {
  MAX_MOVES: number;
  NUM_TILES_TO_GO: number;
  NUMBERS: number[];
  BOARD_SIZE: number;
  targetTileIds: number[];
  UNBREAKABLE_TILE_COUNT: number;
  IMMOVABLE_TILE_COUNT: number;
  HOLES_COUNT: number;
  BOMB_COUNT: number;
}

export const levels: LevelSettings[] = [
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 36,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 6,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 2,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 52,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 50,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 51,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 52,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 49,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 48,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 46,
    NUMBERS: [1, 2, 3, 4],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 50,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 47,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 7,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 50,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 50,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 53,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 53,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 53,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 54,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 50,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 57,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 59,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 55,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 58,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 56,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 1,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5],
    BOARD_SIZE: 8,
    targetTileIds: [1, 2, 3, 4, 5],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 65,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 66,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 65,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 67,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 65,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 67,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 65,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 64,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 66,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 67,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 60,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 67,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 68,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 65,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 61,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 67,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 63,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 66,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 62,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 81,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 67,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 65,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 23,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 29,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 20,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 22,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 69,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 80,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 77,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 24,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 18,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 15,
    NUM_TILES_TO_GO: 70,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 16,
    NUM_TILES_TO_GO: 71,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 73,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 1,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 26,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 19,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 21,
    NUM_TILES_TO_GO: 78,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 0,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 25,
    NUM_TILES_TO_GO: 79,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 0,
    HOLES_COUNT: 0,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 72,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 3,
    IMMOVABLE_TILE_COUNT: 3,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 27,
    NUM_TILES_TO_GO: 75,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 2,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 30,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 1,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 3,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 17,
    NUM_TILES_TO_GO: 74,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
  {
    MAX_MOVES: 28,
    NUM_TILES_TO_GO: 76,
    NUMBERS: [1, 2, 3, 4, 5, 6],
    BOARD_SIZE: 9,
    targetTileIds: [1, 2, 3, 4, 5, 6],
    UNBREAKABLE_TILE_COUNT: 2,
    IMMOVABLE_TILE_COUNT: 1,
    HOLES_COUNT: 2,
    BOMB_COUNT: 0,
  },
];
